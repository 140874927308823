import React from "react";
import Login from "../pages/Login";
import Verification from "../pages/VerificationCode";
// const SignUp = React.lazy(() => import("../pages/SignUp"));
// const LogIn = React.lazy(() => import("../pages/Login"));
// const Verification = React.lazy(() => import("../pages/VerificationCode"));
const OrderingMenu = React.lazy(() => import("../pages/OrderingMenu"));
const Cart = React.lazy(() => import("../pages/Cart"));
const Checkout = React.lazy(() => import("../pages/CheckOut"));
const ProductDetails = React.lazy(() => import("../pages/ProductDetails"));
const Promo = React.lazy(() => import("../pages/PromoCode"));
const Account = React.lazy(() => import("../pages/Account"));
const InviteFriends = React.lazy(() => import("../pages/InviteFriends"));
const StripeWraper = React.lazy(() => import("../pages/Payment"));
const Home = React.lazy(() => import("../pages/Home"));
const YourOrders = React.lazy(() => import("../pages/YourOrders"));

const routes = [
  // {
  //   path: "/",
  //   name: "SignUp",
  //   title: "SignUp",
  //   element: <SignUp />,
  // },
  {
    path: "/login",
    name: "LogIn",
    title: "LogIn",
    element: <Login />,
  },
  {
    path: "/verification",
    name: "Verification",
    title: "Verification",
    element: <Verification />,
  },

  {
    path: "/",
    name: "Home",
    title: "Home",
    element: <OrderingMenu />,
  },
  {
    path: "/menu",
    name: "OrderingMenu",
    title: "OrderingMenu",
    element: <OrderingMenu />,
  },
  {
    path: "/store/:store_id/product-details/:menu_id",
    name: "ProductDetails",
    title: "ProductDetails",
    element: <ProductDetails />,
  },
  {
    path: "/cart",
    name: "Cart",
    title: "Cart",
    element: <Cart />,
  },
  {
    path: "/orders",
    name: "yourorders",
    title: "yourorders",
    element: <YourOrders />,
  },
  {
    path: "/checkout",
    name: "Checkout",
    title: "Checkout",
    element: <Checkout />,
  },
  // {
  //   path: "/product-details/:id",
  //   name: "ProductDetails",
  //   title: "ProductDetails",
  //   element: <ProductDetails />,
  // },
  {
    path: "/promo",
    name: "Promo",
    title: "Promo",
    element: <Promo />,
  },
  {
    path: "/invite-friends",
    name: "inviteFriends",
    title: "InviteFriends",
    element: <InviteFriends />,
  },
  {
    path: "/account",
    name: "Account",
    title: "Account",
    element: <Account />,
  },
  {
    path: "/payment",
    name: "Payment",
    title: "Payment",
    element: <StripeWraper />,
  },
];

export default routes;
