import React from "react";
// import { Bars } from "react-loader-spinner";

function CustomLoader({loading}) {
  return (
    <div className={`loader-container ${loading ? "show" : "hide"}`}>
      <div className="custom-loader">
        <div class="loader"></div>
      </div>
    </div>
  );
}

export default CustomLoader;
