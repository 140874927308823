import { authConstants } from "../_constants";
import { authServices } from "../_services";
import { alert, commonFunctions } from "../_utilities";

export const authActions = {
  register,
  login,
  resendOtp,
  userData,
  updateUserData,
  Address,
  getAddress,
  updateAddress,
  deleteAddress,
  subscribe
};

function register(data) {
  return (dispatch) => {
    dispatch(
      dispatchFunction({
        type: authConstants.REGISTER_USER_REQUEST,
        data: null,
      })
    );
    authServices
      .register(data)
      .then(
        (response) => {
          console.log(response);
          if (response.status == 200) {
            dispatch(
              dispatchFunction({
                type: authConstants.REGISTER_USER_SUCCESS,
                data: response,
              })
            );
            // alert.success(response.message);
          } else {
            dispatch(
              dispatchFunction({
                type: authConstants.REGISTER_USER_FAILURE,
                data: response,
              })
            );
            alert.error(response.message);
          }
        },
        (error) => {
          dispatch(
            dispatchFunction({
              type: authConstants.REGISTER_USER_FAILURE,
              data: error.message,
            })
          );
          alert.error("Oops! Something went wrong.");
        }
      )
      .catch((error) => {
        alert.error("Oops! Something went wrong.");
      });
  };
}

function login(data) {
  return (dispatch) => {
    dispatch(
      dispatchFunction({
        type: authConstants.USER_LOGIN_REQUEST,
        data: null,
      })
    );
    authServices
      .login(data)
      .then(
        (response) => {
          console.log(response);
          if (response.status == 200) {
            dispatch(
              dispatchFunction({
                type: authConstants.USER_LOGIN_SUCCESS,
                data: response,
              })
            );
            alert.success(response.message);
          } else {
            dispatch(
              dispatchFunction({
                type: authConstants.USER_LOGIN_FAILURE,
                data: response,
              })
            );
            alert.error(response.message);
          }
        },
        (error) => {
          dispatch(
            dispatchFunction({
              type: authConstants.USER_LOGIN_FAILURE,
              data: error.message,
            })
          );
          alert.error("Oops! Something went wrong.");
        }
      )
      .catch((error) => {
        alert.error("Oops! Something went wrong.");
      });
  };
}
function resendOtp(data) {
  return (dispatch) => {
    dispatch(
      dispatchFunction({
        type: authConstants.RESEND_OTP_REQUEST,
        data: null,
      })
    );
    authServices
      .resendOtp(data)
      .then(
        (response) => {
          console.log(response);
          if (response.status == 200) {
            dispatch(
              dispatchFunction({
                type: authConstants.RESEND_OTP_SUCCESS,
                data: response,
              })
            );
            alert.success(response.message);
          } else {
            dispatch(
              dispatchFunction({
                type: authConstants.RESEND_OTP_FAILURE,
                data: response,
              })
            );
            alert.error(response.message);
          }
        },
        (error) => {
          dispatch(
            dispatchFunction({
              type: authConstants.RESEND_OTP_FAILURE,
              data: error.message,
            })
          );
          alert.error("Oops! Something went wrong.");
        }
      )
      .catch((error) => {
        alert.error("Oops! Something went wrong.");
      });
  };
}
function userData(data) {
  return (dispatch) => {
    dispatch(
      dispatchFunction({
        type: authConstants.USER_DATA_REQUEST,
        data: null,
      })
    );
    authServices
      .userData(data)
      .then(
        (response) => {
          console.log(response);
          if (response.status == 200) {
            dispatch(
              dispatchFunction({
                type: authConstants.USER_DATA_SUCCESS,
                data: response,
              })
            );
          } else {
            dispatch(
              dispatchFunction({
                type: authConstants.USER_DATA_FAILURE,
                data: response,
              })
            );
            alert.error(response.message);
          }
        },
        (error) => {
          dispatch(
            dispatchFunction({
              type: authConstants.USER_DATA_FAILURE,
              data: error.message,
            })
          );
          alert.error("Oops! Something went wrong.");
        }
      )
      .catch((error) => {
        alert.error("Oops! Something went wrong.");
      });
  };
}
function updateUserData(data) {
  return (dispatch) => {
    dispatch(
      dispatchFunction({
        type: authConstants.UPDATE_USER_DATA_FAILURE,
        data: null,
      })
    );
    authServices
      .updateUserData(data)
      .then(
        (response) => {
          console.log(response);
          if (response.status == 200) {
            dispatch(
              dispatchFunction({
                type: authConstants.UPDATE_USER_DATA_SUCCESS,
                data: response,
              })
            );
          } else {
            dispatch(
              dispatchFunction({
                type: authConstants.UPDATE_USER_DATA_FAILURE,
                data: response,
              })
            );
            alert.error(response.message);
          }
        },
        (error) => {
          dispatch(
            dispatchFunction({
              type: authConstants.UPDATE_USER_DATA_FAILURE,
              data: error.message,
            })
          );
          alert.error("Oops! Something went wrong.");
        }
      )
      .catch((error) => {
        alert.error("Oops! Something went wrong.");
      });
  };
}
function Address(data) {
  return (dispatch) => {
    dispatch(
      dispatchFunction({
        type: authConstants.ADDRESS_REQUEST,
        data: null,
      })
    );
    authServices
      .Address(data)
      .then(
        (response) => {
          if (response.status == 201) {
            dispatch(
              dispatchFunction({
                type: authConstants.ADDRESS_SUCCESS,
                data: response,
              })
            );
            alert.success(response.message);
          } else {
            dispatch(
              dispatchFunction({
                type: authConstants.ADDRESS_FAILURE,
                data: response,
              })
            );
            alert.error(response.message);
          }
        },
        (error) => {
          dispatch(
            dispatchFunction({
              type: authConstants.ADDRESS_FAILURE,
              data: error.message,
            })
          );
          alert.error("Oops! Something went wrong.");
        }
      )
      .catch((error) => {
        alert.error("Oops! Something went wrong.");
      });
  };
}
function getAddress(data) {
  return (dispatch) => {
    dispatch(
      dispatchFunction({
        type: authConstants.USER_ADDRESS_REQUEST,
        data: null,
      })
    );
    authServices
      .getAddress(data)
      .then(
        (response) => {
          console.log(response);
          if (response.status == 200) {
            dispatch(
              dispatchFunction({
                type: authConstants.USER_ADDRESS_SUCCESS,
                data: response,
              })
            );
          } else {
            dispatch(
              dispatchFunction({
                type: authConstants.USER_ADDRESS_FAILURE,
                data: response,
              })
            );
            alert.error(response.message);
          }
        },
        (error) => {
          dispatch(
            dispatchFunction({
              type: authConstants.USER_ADDRESS_FAILURE,
              data: error.message,
            })
          );
          alert.error("Oops! Something went wrong.");
        }
      )
      .catch((error) => {
        alert.error("Oops! Something went wrong.");
      });
  };
}
function updateAddress(id, data) {
  return (dispatch) => {
    dispatch(
      dispatchFunction({
        type: authConstants.UPDATE_ADDRESS_REQUEST,
        data: null,
      })
    );
    authServices
      .updateAddress(id, data)
      .then(
        (response) => {
          console.log(response);
          if (response.status == 200) {
            dispatch(
              dispatchFunction({
                type: authConstants.UPDATE_ADDRESS_SUCCESS,
                data: response,
              })
            );
          } else {
            dispatch(
              dispatchFunction({
                type: authConstants.UPDATE_ADDRESS_FAILURE,
                data: response,
              })
            );
            alert.error(response.message);
          }
        },
        (error) => {
          dispatch(
            dispatchFunction({
              type: authConstants.UPDATE_ADDRESS_FAILURE,
              data: error.message,
            })
          );
          alert.error("Oops! Something went wrong.");
        }
      )
      .catch((error) => {
        alert.error("Oops! Something went wrong.");
      });
  };
}
function deleteAddress(id) {
  return (dispatch) => {
    dispatch(
      dispatchFunction({
        type: authConstants.DELETE_ADDRESS_REQUEST,
        data: null,
      })
    );
    authServices
      .deleteAddress(id)
      .then(
        (response) => {
          console.log(response);
          if (response.status == 200) {
            dispatch(
              dispatchFunction({
                type: authConstants.DELETE_ADDRESS_SUCCESS,
                data: response,
              })
            );
            alert.success(response.message);
          } else {
            dispatch(
              dispatchFunction({
                type: authConstants.DELETE_ADDRESS_FAILURE,
                data: response,
              })
            );
            alert.error(response.message);
          }
        },
        (error) => {
          dispatch(
            dispatchFunction({
              type: authConstants.DELETE_ADDRESS_FAILURE,
              data: error.message,
            })
          );
          alert.error("Oops! Something went wrong.");
        }
      )
      .catch((error) => {
        alert.error("Oops! Something went wrong.");
      });
  };
}
function subscribe(data) {
  return (dispatch) => {
    dispatch(
      dispatchFunction({
        type: authConstants.SUBSCRIBE_REQUEST,
        data: null,
      })
    );
    authServices
      .subscribe(data)
      .then(
        (response) => {
          console.log(440, response.status);
          if (response.status == 200) {
            dispatch(
              dispatchFunction({
                type: authConstants.SUBSCRIBE_SUCCESS,
                data: response.data,
              })
            );
            alert.success("Thank you for subscribing!");
          } else {
            dispatch(
              dispatchFunction({
                type: authConstants.SUBSCRIBE_FAILURE,
                data: response.data,
              })
            );
            alert.error(response.message);
          }
        },
        (error) => {
          dispatch(
            dispatchFunction({
              type: authConstants.SUBSCRIBE_FAILURE,
              data: error.message,
            })
          );
          alert.error("Oops! Something went wrong.");
        }
      )
      .catch((error) => {
        alert.error("Oops! Something went wrong.");
      });
  };
}

function dispatchFunction(data) {
  // if (data.data && data.data.code === 401) {
  //     commonFunctions.onLogout();
  //     return {
  //         type: authConstants.USER_LOGOUT,
  //         data: null,
  //     };
  // }
  return {
    type: data.type,
    data: data.data,
  };
}
